import { useEffect, useState } from 'react';

import Session from 'supertokens-auth-react/recipe/session';

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState<string | undefined>();

  useEffect(() => {
    const checkSession = async () => {
      const [accessToken, sessionExists] = await Promise.all([
        Session.getAccessToken(),
        Session.doesSessionExist(),
      ]);
      setIsLoggedIn(sessionExists);
      setAccessToken(accessToken);
    };

    checkSession();
  }, []);

  return { isLoggedIn, accessToken };
};
