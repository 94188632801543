import { z, ZodError } from 'zod';

export const envSchema = z.object({
  VITE_CLIENT_API: z.string().url(),
  VITE_SOCKET_URL: z.string().url(),
  VITE_SENTRY_URL: z.string().url(),
  VITE_CLIENT_LOCAL: z.string().url(),
  VITE_CLIENT_URL: z.string().url().optional(),
  VITE_SESSION_TOKEN_FRONTEND_DOMAIN: z.string(),
  VITE_VAPID_PUBLIC_KEY: z.string(),
});

try {
  envSchema.parse(import.meta.env);
} catch (error) {
  if (error instanceof ZodError) {
    console.error('Environment validation error:', error.errors);
  }
}

const envVariables = envSchema.parse(import.meta.env);

export const safeEnv = envVariables;
