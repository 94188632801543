export enum ECurrency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CNY = 'CNY',
  RUB = 'RUB',
  CHF = 'CHF',
  AED = 'AED',
  PLN = 'PLN',
  RSD = 'RSD',
  THB = 'THB',
  AZN = 'AZN',
  RON = 'RON',
  TRY = 'TRY',
  BHD = 'BHD',
  QAR = 'QAR',
  MAD = 'MAD',
  OMR = 'OMR',
  UZS = 'UZS',
  BGN = 'BGN',
}
