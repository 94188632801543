import { useEffect, useLayoutEffect, useState } from 'react';

export const breakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

// Should be changed to useMediaQuery.
export const useBreakpoint = () => {
  const [current, setCurrent] = useState(window?.innerWidth || 0);

  const up = (breakpoint: keyof typeof breakpoints) => {
    return current >= breakpoints[breakpoint];
  };

  const down = (breakpoint: keyof typeof breakpoints) => {
    return current < breakpoints[breakpoint];
  };

  const onWindowResize = () => {
    setCurrent(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  useLayoutEffect(() => {
    onWindowResize();
  }, []);

  return {
    current,
    up,
    down,
  };
};
