import { GenericStateCreator } from '../types';

interface NotificationState {
  pushPermission: NotificationPermission;
}

interface NotificationActions {
  setNotificationPermission: (pushPermission: NotificationPermission) => void;
}

export type NotificationSlice = NotificationState & NotificationActions;

const initialState: NotificationState = {
  pushPermission: 'default',
};

export const createNotificationSlice: GenericStateCreator<NotificationSlice> = (
  set,
) => ({
  ...initialState,
  setNotificationPermission: (pushPermission: NotificationPermission) => {
    set({ pushPermission });
  },
});
