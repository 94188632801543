import React, { useState } from 'react';

import { cn } from '@lib/utils';
import { useBreakpoint } from '@shared/hooks/use-breakpoint';
import { toast } from 'sonner';

import { ButtonProps } from './button';

export interface IHandleActionClickParams {
  dismiss: typeof toast.dismiss;
}

export type TOnActionButtonClick = (params: IHandleActionClickParams) => void;

export interface NotificationProps {
  type: 'success' | 'error' | 'info' | 'warning';
  title?: string;
  id?: string | number;
  description?: string;
  loader?: boolean;
  onUndo?: () => void;
  undoText?: string;
  actionButton1Text?: string;
  actionButton1OnClick?: TOnActionButtonClick;
  actionButton2Text?: string;
  actionButton2OnClick?: TOnActionButtonClick;
  cancelButtonText?: string;
  cancelButtonOnClick?: TOnActionButtonClick;

  slotProps?: {
    buttons?: ButtonProps;
  };
}

type NotificationType = 'success' | 'error' | 'info' | 'warning';

const colorClassName = (type: NotificationType, loader: boolean) => {
  const baseClasses: Record<NotificationType, string> = {
    success: 'underline-success',
    error: 'underline-error',
    info: 'underline-info',
    warning: 'underline-warning',
  };

  const shadeSuffix = loader ? '' : '-shade';

  const background = `underline-container-${type}${shadeSuffix}`;
  const line = `${baseClasses[type]}${shadeSuffix}`;

  return { background, line };
};

const MainNotification: React.FC<NotificationProps> = ({
  type,
  title,
  description,
  loader = false,
  undoText,
  onUndo,
  actionButton1Text,
  actionButton1OnClick,
  actionButton2Text,
  actionButton2OnClick,
  id,
  cancelButtonText,
  cancelButtonOnClick,
  slotProps,
}) => {
  const breakpoint = useBreakpoint();
  const { background, line } = colorClassName(type, loader);
  const [hoveredCross, setHoveredCross] = useState(false);

  const handleActionButtonClick = (cb?: TOnActionButtonClick) =>
    cb && cb.bind(null, { dismiss: (newId) => toast.dismiss(newId ?? id) });

  return (
    <>
      <div
        className={cn(background, 'absolute h-[100%] w-[95%]', {
          hidden:
            actionButton1Text && actionButton2Text && breakpoint.down('md'),
        })}
      />
      <div
        className={cn(line, {
          hidden:
            actionButton1Text && actionButton2Text && breakpoint.down('md'),
        })}
      ></div>
      <div className="absolute bottom-[0] left-0 z-10 h-full w-full rounded-[16px] backdrop-blur-[40px]"></div>
      <div className="relative z-10 flex h-full w-full min-w-[325px] max-w-[400px] flex-col rounded-[16px] text-[white]">
        <div
          className={cn('flex w-full flex-grow items-center gap-[12px]', {
            'justify-center':
              actionButton1Text && actionButton2Text && breakpoint.down('md'),
            'justify-between': !(
              actionButton1Text &&
              actionButton2Text &&
              breakpoint.down('md')
            ),
          })}
        >
          <div
            className={cn(
              { 'min-w-[240px]': breakpoint.up('md') },
              'relative w-full pb-[12px] pl-[16px] pt-[12px]',
            )}
          >
            {cancelButtonText && breakpoint.down('md') && (
              <div className="absolute bottom-[0] left-0 z-10 h-full w-full rounded-[16px] backdrop-blur-[40px]"></div>
            )}
            {actionButton1Text &&
              actionButton2Text &&
              !cancelButtonText &&
              breakpoint.down('md') && (
                <div className="absolute bottom-[0] left-0 z-10 h-full w-full rounded-[16px] backdrop-blur-[40px]"></div>
              )}
            <div className="relative z-20">
              {title && (
                <p
                  className={cn(
                    'font-ttf pb-[6px] pt-[7px] text-base font-medium leading-[120%]',
                    {
                      'text-center':
                        actionButton1Text &&
                        actionButton2Text &&
                        breakpoint.down('md'),
                    },
                  )}
                >
                  {title}
                </p>
              )}
              {description && (
                <p
                  className={cn(
                    'font-feature-settings-variant-1 font-ttf max-w-full py-[8px] text-xs font-medium leading-[150%] text-[#D1D5DB] md:w-max',
                    {
                      'text-center':
                        actionButton1Text &&
                        actionButton2Text &&
                        breakpoint.down('md'),
                    },
                  )}
                >
                  {description}
                </p>
              )}
            </div>
            {actionButton1Text && actionButton2Text && breakpoint.up('md') && (
              <div className="relative flex gap-[8px] py-[8px]">
                {actionButton1Text && (
                  <button
                    onClick={handleActionButtonClick(actionButton1OnClick)}
                    {...slotProps?.buttons}
                    className={cn(
                      'font-ttf rounded-[12px] border-[2px] border-[#6F767E33] bg-[#F6F6F9] px-[16px] py-[9px] text-sm font-medium leading-[100%] tracking-[0.56px] text-[#1A1D1F]',
                      slotProps?.buttons?.className,
                    )}
                  >
                    {actionButton1Text}
                  </button>
                )}
                {actionButton2Text && (
                  <button
                    onClick={handleActionButtonClick(actionButton2OnClick)}
                    {...slotProps?.buttons}
                    className={cn(
                      'font-ttf rounded-[12px] border-[2px] border-[#6F767E33] px-[16px] py-[9px] text-sm font-medium leading-[100%] tracking-[0.56px]',
                      slotProps?.buttons?.className,
                    )}
                  >
                    {actionButton2Text}
                  </button>
                )}
              </div>
            )}
          </div>
          <div className={cn('flex self-start', { 'self-center': undoText })}>
            {onUndo && (
              <button
                onClick={onUndo}
                className="font-ttf whitespace-nowrap border-l-[1px] border-r-[1px] border-[#FFFFFF1A] px-[20px] py-[8px]"
              >
                {undoText || 'Cancel'}
              </button>
            )}
            <button
              onClick={() => toast.dismiss(id)}
              className={cn('font-ttf p-[12px] text-lg focus:outline-none', {
                hidden:
                  actionButton1Text &&
                  actionButton2Text &&
                  breakpoint.down('md'),
              })}
              onMouseEnter={() => {
                setHoveredCross(true);
              }}
              onMouseLeave={() => {
                setHoveredCross(false);
              }}
            >
              {!hoveredCross ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 8.88906L13.8891 5L15 6.11094L11.1109 10L15 13.8891L13.8891 15L10 11.1109L6.11094 15L5 13.8891L8.88906 10L5 6.11094L6.11094 5L10 8.88906Z"
                    fill="#BBBEC3"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 8.88906L13.8891 5L15 6.11094L11.1109 10L15 13.8891L13.8891 15L10 11.1109L6.11094 15L5 13.8891L8.88906 10L5 6.11094L6.11094 5L10 8.88906Z"
                    fill="white"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        {actionButton1Text && actionButton2Text && breakpoint.down('md') && (
          <div
            className={cn('relative flex px-[16px]', {
              'flex-col': cancelButtonText,
            })}
          >
            <div
              className={`${background}-action absolute h-[100%] w-[100%]`}
            />
            <div className={`${line}-action`} />
            <button
              onClick={handleActionButtonClick(actionButton1OnClick)}
              className={cn(
                'font-ttf #D1D5DB relative w-[50%] py-[17px] text-sm font-medium leading-[100%] tracking-[0.56px]',
                { 'w-full': cancelButtonText },
                { 'border-r-[1px]': !cancelButtonText },
                {
                  'border-b-[1px] border-r-[0px] border-[#FFFFFF1A]':
                    cancelButtonText,
                },
              )}
            >
              {actionButton1Text}
            </button>
            <button
              onClick={handleActionButtonClick(actionButton2OnClick)}
              className={cn(
                'font-ttf #D1D5DB relative w-[50%] py-[17px] text-sm font-medium leading-[100%] tracking-[0.56px]',
                { 'w-full': cancelButtonText },
                { 'border-b-[1px] border-[#FFFFFF1A]': cancelButtonText },
              )}
            >
              {actionButton2Text}
            </button>
            {cancelButtonText && (
              <button
                onClick={handleActionButtonClick(cancelButtonOnClick)}
                className="font-ttf #D1D5DB relative w-[100%] py-[17px] text-sm font-medium leading-[100%] tracking-[0.56px]"
              >
                {cancelButtonText}
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MainNotification;
