import { safeEnv } from '@/shared/lib';
import getCookieHandler from '@app/config/cookieHandler.ts';
import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import { EmailVerificationPreBuiltUI } from 'supertokens-auth-react/recipe/emailverification/prebuiltui';
import Session from 'supertokens-auth-react/recipe/session';

const websiteDomain = safeEnv.VITE_CLIENT_URL ?? safeEnv.VITE_CLIENT_LOCAL;

SuperTokens.init({
  cookieHandler: getCookieHandler,
  appInfo: {
    appName: 'GCard: A premium payment card',
    apiDomain: safeEnv.VITE_CLIENT_API,
    websiteDomain,
    apiBasePath: '/auth',
    websiteBasePath: '/auth',
  },
  style: `
    [data-supertokens~=container] {
      --palette-primary: 246, 246, 249;
    }
  `,
  recipeList: [
    EmailPassword.init({
      style: `
        [data-supertokens~=container] {
          max-width: 480px !important;
          width: 100% !important;
          background: none !important;
          font-family: 'TTFirsNeue', sans-serif !important;
          padding-inline: 24px !important;
          box-sizing: border-box !important;
        }

        [data-supertokens~=container] form {
          display: flex !important;
          flex-direction: column !important;
          gap: 24px !important;
        }

        [data-supertokens~="primaryText"] {
          color: unset !important;
        }

        [data-supertokens~=headerTitle], [data-supertokens~=headerSubtitle] {
          color: var(--Neutral-01, #F6F6F9) !important;
        }

        [data-supertokens~=authPage] [data-supertokens~=headerSubtitle] {
            display: none;
        }

        [data-supertokens~=authPage] [data-supertokens~=headerTitle] {
            display: none;
        }

        [data-supertokens~='divider'] {
          display: none !important;
        }

        [data-supertokens~='label'] {
          color: var(--Neutral-01, #F6F6F9) !important;
          font-variant-numeric: lining-nums tabular-nums !important;
          font-feature-settings: 'cv02' on, 'cv03' on, 'cv04' on, 'cv11' on, 'liga' off, 'clig' off, 'calt' off !important;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 150% !important;
          margin-bottom: 6px !important;
        }

        [data-supertokens~="formRow"] {
          padding-bottom: 0 !important;
        }

        [data-supertokens~=container] > [data-supertokens~='row'] {
          width: 100% !important;
        }

        [data-supertokens~='superTokensBranding'] {
          display: none !important;
        }

        [data-supertokens~='inputWrapper'] {
          border: none !important;
          outline: none !important;
          height: 52px !important;
          overflow: hidden !important;
          background: var(--Neutral-07, #1A1D1F) !important;
        }

        [data-supertokens="inputContainer"] {
          margin: 0 !important;
          overflow: hidden !important;
          border-radius: 8px !important;
        }

        [data-supertokens~='inputWrapper']:focus-within {
          outline: none !important;
          box-shadow: none !important;
        }

        [data-supertokens~='inputWrapper'] > input {
          height: 100% !important;
          padding: 14px 16px !important;
          border: none !important;
          outline: none !important;
          color: var(--Neutral-01, #F6F6F9) !important;
          border-radius: 0 !important;
          font-family: 'TTFirsNeue', sans-serif !important;
        }

        [data-supertokens~='inputWrapper'] > input:-webkit-autofill {
          background-color: light-dark(rgb(255, 235, 153), rgba(70, 90, 126, 0.4)) !important;
          box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000) !important;
          -webkit-text-fill-color: var(--Neutral-01, #F6F6F9) !important;
        }

        [data-supertokens~='button'] {
          display: flex !important;
          height: 60px !important;
          padding: 12px 24px !important;
          justify-content: center !important;
          align-items: center !important;
          font-family: 'TTFirsNeue', sans-serif !important;
          gap: 8px !important;
          align-self: stretch !important;
          border-radius: 12px !important;
          background: var(--Neutral-01, #F6F6F9) !important;
          color: black !important;
          border: none !important;
          leading-trim: both !important;
          text-edge: cap !important;
          font-feature-settings: 'liga' off, 'calt' off !important;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 100% !important;
          letter-spacing: 0.16px !important;
          text-transform: capitalize !important;
        }

        [data-supertokens~="backButton"] {
          background: var(--Neutral-01, #F6F6F9) !important;
          border-radius: 12px !important;
          width: 30px !important;
          height: 30px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
        }

        [data-supertokens~="backButton"] svg {
          width: 12px !important;
        }

        [data-supertokens~="backButtonCommon"] {
          width: 30px !important;
        }

        [data-supertokens~=container] a {
          color: #F6F6F9 !important;
          opacity: 0.5 !important;
          text-decoration: underline !important;
          transition: opacity 0.2s ease-in-out !important;
          font-family: 'TTFirsNeue', sans-serif !important;
        }

        [data-supertokens~=container] a:hover {
          opacity: 0.4 !important;
        }

        [data-supertokens~="inputAdornment"] {
          margin-right: 16px !important;
        }
      `,
    }),
    EmailVerification.init({
      mode: 'REQUIRED',
    }),
    Session.init({
      tokenTransferMethod: 'header',
    }),
  ],
});

export const PreBuiltUIList = [
  EmailPasswordPreBuiltUI,
  EmailVerificationPreBuiltUI,
];
