import { createRoot } from 'react-dom/client';

import App from '@app/app.tsx';

const rootNode = document.getElementById('root');

if (!rootNode) {
  throw new Error('Root node not found');
}

createRoot(rootNode).render(<App />);
