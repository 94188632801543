import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;
const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group bottom-[92px] w-full"
      toastOptions={{
        classNames: {
          toast:
            'border-2 border-b-[0] border-[#FFFFFF1A] w-fit bg-neutral06 right-[0px] rounded-[16px] border-0 p-0 group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        },
        duration: 3000,
      }}
      {...props}
    />
  );
};

export { Toaster };
