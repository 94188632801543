import { ECurrency } from '@lib/currency';

export const currencyCodes = {
  [ECurrency.USD]: 'us',
  [ECurrency.EUR]: 'eu',
  [ECurrency.GBP]: 'gb',
  [ECurrency.CNY]: 'cn',
  [ECurrency.RUB]: 'ru',
  [ECurrency.CHF]: 'ch',
  [ECurrency.AED]: 'ae',
  [ECurrency.PLN]: 'pl',
  [ECurrency.RSD]: 'rs',
  [ECurrency.THB]: 'th',
  [ECurrency.AZN]: 'az',
  [ECurrency.RON]: 'ro',
  [ECurrency.TRY]: 'tr',
  [ECurrency.BHD]: 'bh',
  [ECurrency.QAR]: 'qa',
  [ECurrency.MAD]: 'ma',
  [ECurrency.OMR]: 'om',
  [ECurrency.UZS]: 'uz',
  [ECurrency.BGN]: 'bg',
};

export const CURRENCY_NAMES = {
  [ECurrency.USD]: 'US Dollar',
  [ECurrency.EUR]: 'Euro',
  [ECurrency.GBP]: 'British Pound',
  [ECurrency.CNY]: 'Chinese Yuan',
  [ECurrency.RUB]: 'Russian Ruble',
  [ECurrency.CHF]: 'Swiss Franc',
  [ECurrency.AED]: 'UAE Dirham',
  [ECurrency.PLN]: 'Polish Zloty',
  [ECurrency.RSD]: 'Serbian Dinar',
  [ECurrency.THB]: 'Thai Baht',
  [ECurrency.AZN]: 'Azerbaijani Manat',
  [ECurrency.RON]: 'Romanian Leu',
  [ECurrency.TRY]: 'Turkish Lira',
  [ECurrency.BHD]: 'Bahraini Dinar',
  [ECurrency.QAR]: 'Qatari Riyal',
  [ECurrency.MAD]: 'Moroccan Dirham',
  [ECurrency.OMR]: 'Omani Rial',
  [ECurrency.UZS]: 'Uzbekistani Som',
  [ECurrency.BGN]: 'Bulgarian Lev',
};

export const getCurrencies = () => {
  return Object.values(ECurrency);
};
